import React from 'react'
import styled from 'styled-components'

import SEO from '../components/seo'
import Main from '../layouts/main'
import { BigHeader } from '../molecules/BigHeader/BigHeader'
import { ContactDetails } from '../molecules/ContactDetalis/ContactDetails'
import LeafletMap from '../molecules/Map/LeafletMap'
import { SectionWrapper } from '../molecules/SectionWrapper/SectionWrapper'
import { Breakpoints } from '../styles/breakpoints'
import { Colors } from '../styles/colors'

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  ${Breakpoints.small} {
    grid-template-columns: 1fr;
  }
`

const ContactPage = () => (
  <Main>
    <SEO title='Poradnia Rehabilitacyjna Azory' />
    <BigHeader
      headerText='Jak się umówić?'
      backgroundColor={Colors.white}
      headerColor={Colors.secondary}
    />
    <SectionWrapper backgroundColor={Colors.white} paddingTop paddingBottom>
      <Layout>
        <ContactDetails />
        {typeof window !== 'undefined' && <LeafletMap />}
      </Layout>
    </SectionWrapper>
  </Main>
)

export default ContactPage
