import MailIcon from '@material-ui/icons/Mail'
import PersonIcon from '@material-ui/icons/Person'
import PhoneIcon from '@material-ui/icons/Phone'
import React from 'react'
import styled from 'styled-components'

import { Text } from '../../atoms/Typography/Text'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Bold, NonStyledAnchor } from '../../styles/common'
import { Spacings } from '../../styles/spacings'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const MainLayout = styled(Layout)`
  margin-top: ${Spacings['32']};
  margin-bottom: ${Spacings['32']};

  ${Breakpoints.small} {
    margin-top: 0;
  }
`

const ContactPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledPhoneIcon = styled(PhoneIcon)`
  width: 35px;
  height: 35px;
  margin-right: ${Spacings['22']};
`

const StyledMailIcon = styled(MailIcon)`
  width: 35px;
  height: 35px;
  margin-right: ${Spacings['22']};
`

const StyledPersonIcon = styled(PersonIcon)`
  width: 35px;
  height: 35px;
  margin-right: ${Spacings['22']};
`

export const ContactDetails = () => (
  <MainLayout>
    <ContactPart>
      <StyledPhoneIcon style={{ color: Colors.secondary }} />
      <Layout>
        <Text variant='large'>Telefonicznie</Text>
        <Text font='Roboto' variant='xLarge'>
          <Bold>
            <NonStyledAnchor href='tel:12.637.21.16'>12 637 21 16</NonStyledAnchor>
          </Bold>
        </Text>
      </Layout>
    </ContactPart>
    <ContactPart>
      <StyledMailIcon style={{ color: Colors.secondary }} />
      <Layout>
        <Text variant='large'>Mailowo</Text>
        <Text font='Roboto' variant='xLarge'>
          <Bold>
            <NonStyledAnchor href='mailto:rehazory@gmail.com'>rehazory@gmail.com</NonStyledAnchor>
          </Bold>
        </Text>
      </Layout>
    </ContactPart>
    <ContactPart>
      <StyledPersonIcon style={{ color: Colors.secondary }} />
      <Layout>
        <Text variant='large'>Osobiście</Text>
        <Text font='Roboto' variant='xLarge'>
          <Bold>ul. Nałkowskiego 1</Bold>
        </Text>
        <Text font='Roboto' variant='xLarge'>
          <Bold>31-308 Kraków</Bold>
        </Text>
      </Layout>
    </ContactPart>
  </MainLayout>
)
