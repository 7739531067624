import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import styled from 'styled-components'

const StyledMap = styled(MapContainer)`
  height: 400px;
  width: 100%;
`

const LeafletMap = () => {
  // Latitude and Longitude of the map centre in an array, eg [51, -1]
  // geo:50.08312,19.91307?z=19
  const position = [50.08311, 19.91345]
  // Initial zoom level for the map (default 13)
  const zoom = 17
  // If set, will display a marker, which when clicked will display this text
  const markerText = 'Przychodnia Rehabilitacyjna Azory'

  return (
    <StyledMap center={position} zoom={zoom} scrollWheelZoom={false}>
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {markerText !== '' && (
        <Marker position={position}>
          <Popup>{markerText}</Popup>
        </Marker>
      )}
    </StyledMap>
  )
}

export default LeafletMap
